body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.if-title {
  color : #013371; 
  font-weight : bold !important;
}

.if-subtitle {
  font-weight: bold !important; 
  color : #65676B;
}

.if-subtitle-icon {
  font-size : 0.9rem !important;
  padding-right : 5px; 
  color : #65676B;
}

.if-body {
  margin-left : 1.2rem !important;

}

.Vehicle-row-container {
  cursor: pointer;
  background-color : #ffffff;
  display : flex;
  flex : 1;
  padding : 0.75rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.2s;
}

.Vehicle-row-container.selected {
  transform: scale(1.03);
  border: 2px solid #01579b;
}

.Vehicle-row-container:hover {
  transform: scale(1.03);
  border: 2px solid #01579b;

}


.User-row-container {
  max-height: 50px;
  cursor: pointer;
  background-color : #ffffff;
  display : flex;
  flex : 1;
  padding : 0.75rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.2s;
}

.User-row-container.selected {
  transform: scale(1.01);
  border: 2px solid #01579b;
}

.User-row-container:hover {
  transform: scale(1.01);
  border: 2px solid #01579b;

}

.button-play-history {
  color : #673ab7 !important;
  font-size: 40px !important;
}

.button-pause-history {
  color : #ff8f00 !important;
  font-size: 40px !important;
}